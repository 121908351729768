/* css-side-effects-plugin-ns:app/tailwind.css */
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
::before,
::after {
  --tw-content: "";
}
html,
:host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family:
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  line-height: inherit;
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp,
pre {
  font-family:
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
dialog {
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
button,
[role=button] {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}
[hidden] {
  display: none;
}
@font-face {
  font-family: "neue-regrade";
  src: url(/neue_regrade.woff2) format("woff2-variations");
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "neue-regrade";
  text-rendering: optimizeSpeed;
  -webkit-tap-highlight-color: transparent;
  background-color: #f4f4f4;
}
body,
main {
  min-height: 100vh;
  min-height: 100dvh;
}
@media not all and (min-width: 768px) {
  main > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
}
main > section {
  max-width: 100%;
  overflow: hidden;
  border-radius: 15px;
}
@media (min-width: 768px) {
  main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a a" "b v" "\111  g" "e e" "d \17e" "z i" "z k" "l j";
  }
  main > section {
    aspect-ratio: unset;
  }
  #intro {
    grid-area: a;
  }
  #watch {
    grid-area: b;
  }
  #latest-work {
    grid-area: v;
  }
  #recognitions {
    grid-area: \111;
  }
  #logos {
    grid-area: g;
  }
  #video {
    grid-area: e;
  }
  #slogan {
    grid-area: d;
  }
  #milijarde {
    grid-area: \17e;
  }
  #faq {
    grid-area: z;
  }
  #djordje {
    grid-area: k;
  }
  #features {
    grid-area: l;
  }
  #misc {
    grid-area: i;
  }
  #subscribe {
    grid-area: j;
  }
}
@media (max-width: 1500px) {
  #intro {
    min-height: 220px;
  }
  #watch,
  #latest-work,
  #recognitions,
  #logos {
    min-height: 220px;
    aspect-ratio: 3/2;
  }
  #misc,
  #video {
    min-height: 220px;
    aspect-ratio: 16/9;
  }
  #slogan,
  #milijarde,
  #djordje {
    min-height: 220px;
    aspect-ratio: 3/2;
  }
  #features {
    min-height: -moz-max-content;
    min-height: max-content;
  }
  #subscribe {
    min-height: 320px;
    aspect-ratio: 3/2;
  }
}
@media (min-width: 1500px) {
  main {
    display: grid;
    height: 100%;
    max-height: 100vh;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-template-areas: "a a b b v v g g d d" "a a b b v v g g d d" "\111  \111  e e e e e e d d" "\111  \111  e e e e e e \17e  \17e" "\111  \111  e e e e e e \17e  \17e" "z z e e e e e e i i" "z z e e e e e e i i" "z z k k l l j j j j" "z z k k l l j j j j" "z z k k l l j j j j";
  }
  main > section {
    min-height: unset;
    aspect-ratio: unset;
  }
}
.fs-heading {
  font-size: 34px;
}
.fs-p {
  font-size: 17px;
}
.fs-small {
  font-size: 15px;
}
@media (min-width: 1500px) {
  .fs-heading {
    font-size: clamp(1.75rem, 0.855vw + 0.949rem, 3rem);
  }
  .fs-p {
    font-size: clamp(0.875rem, 0.855vw + 0.074rem, 2.125rem);
  }
  .fs-small {
    font-size: clamp(0.75rem, 0.769vw + 0.029rem, 1.875rem);
  }
}
#watch {
  background-image: url(/mars.png);
  background-size: cover;
}
#slogan {
  background-image: url(/slogan.png);
  background-size: cover;
}
#slogan p {
  font-size: clamp(1.125rem, 1.86vw + -0.108rem, 2.125rem);
}
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
.cursor {
  background-color: #ffcc33;
  mix-blend-mode: difference;
  display: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  top: -20px;
  left: -20px;
  position: fixed;
  will-change: transform;
  z-index: 1000;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  transition:
    width 0.15s,
    height 0.15s,
    top 0.15s,
    left 0.15s,
    opacity 0.15s;
}
.cursor.cursor--initialized {
  display: block;
}
.cursor.cursor--off-screen {
  opacity: 0;
}
.cursor.cursor--clicking {
  width: 38px;
  height: 38px;
  top: -19px;
  left: -19px;
}
.clock {
  border-radius: 50%;
  text-align: center;
  position: relative;
  border: 4px solid white;
}
@media (max-width: 1499px) {
  .clock {
    aspect-ratio: 1 / 1;
    height: 100%;
    max-height: 50%;
    max-width: 50%;
  }
}
@media (min-width: 1500px) {
  .clock {
    aspect-ratio: 1 / 1;
    height: 110px;
    width: 110px;
  }
}
@media (min-width: 2000px) {
  .clock {
    height: 160px;
    width: 160px;
  }
}
.clock::after {
  background: white;
  content: "";
  width: 9%;
  height: 9%;
  max-width: 10px;
  max-height: 10px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hour_hand {
  position: absolute;
  width: 4px;
  height: 25%;
  background: white;
  top: 25%;
  left: 48.4%;
  transform-origin: bottom;
  border-radius: 4px;
}
.min_hand {
  position: absolute;
  width: 4px;
  height: 35%;
  background: white;
  top: 15%;
  left: 48%;
  transform-origin: bottom;
  border-radius: 4px;
}
.sec_hand {
  position: absolute;
  width: 1px;
  height: 40%;
  background: #ff4500;
  top: 9.5%;
  left: 50%;
  transform-origin: bottom;
  border-radius: 1px;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none {
  pointer-events: none;
}
.invisible {
  visibility: hidden;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  inset: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-4 {
  bottom: 1rem;
}
.left-0 {
  left: 0px;
}
.left-4 {
  left: 1rem;
}
.left-5 {
  left: 1.25rem;
}
.left-\[2px\] {
  left: 2px;
}
.right-0 {
  right: 0px;
}
.right-\[10px\] {
  right: 10px;
}
.top-0 {
  top: 0px;
}
.top-4 {
  top: 1rem;
}
.top-8 {
  top: 2rem;
}
.top-\[100\%\] {
  top: 100%;
}
.top-\[1px\] {
  top: 1px;
}
.-z-10 {
  z-index: -10;
}
.z-20 {
  z-index: 20;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.mt-auto {
  margin-top: auto;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.hidden {
  display: none;
}
.\!h-full {
  height: 100% !important;
}
.h-\[1\.2em\] {
  height: 1.2em;
}
.h-\[1\.5em\] {
  height: 1.5em;
}
.h-\[28px\] {
  height: 28px;
}
.h-\[30px\] {
  height: 30px;
}
.h-\[7px\] {
  height: 7px;
}
.h-full {
  height: 100%;
}
.max-h-\[30\%\] {
  max-height: 30%;
}
.max-h-\[40\%\] {
  max-height: 40%;
}
.max-h-\[50\%\] {
  max-height: 50%;
}
.\!w-auto {
  width: auto !important;
}
.w-\[1\.2em\] {
  width: 1.2em;
}
.w-\[1\.5em\] {
  width: 1.5em;
}
.w-\[140px\] {
  width: 140px;
}
.w-\[28px\] {
  width: 28px;
}
.w-\[60px\] {
  width: 60px;
}
.w-\[7px\] {
  width: 7px;
}
.w-full {
  width: 100%;
}
.max-w-\[30\%\] {
  max-width: 30%;
}
.max-w-\[40\%\] {
  max-width: 40%;
}
.max-w-\[50\%\] {
  max-width: 50%;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.basis-full {
  flex-basis: 100%;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
  cursor: pointer;
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.flex-col {
  flex-direction: column;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-stretch {
  align-items: stretch;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-stretch {
  justify-content: stretch;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-\[0\.6em\] {
  gap: 0.6em;
}
.gap-\[10px\] {
  gap: 10px;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-black\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 21 21 / 0.1);
}
.divide-white\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.1);
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.rounded-2xl {
  border-radius: 15px;
}
.rounded-\[100px\] {
  border-radius: 100px;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-none {
  border-radius: 0px;
}
.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.border-black\/10 {
  border-color: rgb(21 21 21 / 0.1);
}
.border-white\/10 {
  border-color: rgb(255 255 255 / 0.1);
}
.bg-\[\#0073AA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 115 170 / var(--tw-bg-opacity));
}
.bg-\[\#C248A0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(194 72 160 / var(--tw-bg-opacity));
}
.bg-\[\#DFD1B4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(223 209 180 / var(--tw-bg-opacity));
}
.bg-\[\#ECE5D6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 229 214 / var(--tw-bg-opacity));
}
.bg-\[\#EDEDED\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 237 237 / var(--tw-bg-opacity));
}
.bg-\[\#FFCC33\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 204 51 / var(--tw-bg-opacity));
}
.bg-\[\#eae0d1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 224 209 / var(--tw-bg-opacity));
}
.bg-\[black\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity));
}
.bg-creme {
  --tw-bg-opacity: 1;
  background-color: rgb(228 191 136 / var(--tw-bg-opacity));
}
.bg-dust {
  --tw-bg-opacity: 1;
  background-color: rgb(236 228 214 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 204 51 / var(--tw-bg-opacity));
}
.fill-black {
  fill: #151515;
}
.fill-current {
  fill: currentColor;
}
.fill-white {
  fill: #fff;
}
.fill-yellow {
  fill: #FFCC33;
}
.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-\[0\.4em\] {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.py-\[1em\] {
  padding-top: 1em;
  padding-bottom: 1em;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.font-\[system-ui\,sans-serif\] {
  font-family: system-ui, sans-serif;
}
.text-\[50vw\] {
  font-size: 50vw;
}
.font-\[800\] {
  font-weight: 800;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-medium {
  font-weight: 500;
}
.leading-\[0\.9\] {
  line-height: 0.9;
}
.leading-none {
  line-height: 1;
}
.leading-tight {
  line-height: 1.25;
}
.tracking-\[-0\.08em\] {
  letter-spacing: -0.08em;
}
.text-\[\#DD1B36\] {
  --tw-text-opacity: 1;
  color: rgb(221 27 54 / var(--tw-text-opacity));
}
.text-\[\#FF4500\] {
  --tw-text-opacity: 1;
  color: rgb(255 69 0 / var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(21 21 21 / var(--tw-text-opacity));
}
.text-red {
  --tw-text-opacity: 1;
  color: rgb(255 69 0 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 204 51 / var(--tw-text-opacity));
}
.accent-black {
  accent-color: #151515;
}
.opacity-0 {
  opacity: 0;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-80 {
  opacity: 0.8;
}
.mix-blend-lighten {
  mix-blend-mode: lighten;
}
.transition {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    -webkit-backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-colors {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-none {
  transition-property: none;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.duration-\[400ms\] {
  transition-duration: 400ms;
}
.duration-\[450ms\] {
  transition-duration: 450ms;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media not all and (min-width: 1500px) {
  .max-2xl\:\!fs-heading {
    font-size: 34px !important;
  }
  @media (min-width: 1500px) {
    .max-2xl\:\!fs-heading {
      font-size: clamp(1.75rem, 0.855vw + 0.949rem, 3rem) !important;
    }
  }
}
.\*\:h-full > * {
  height: 100%;
}
.\*\:w-full > * {
  width: 100%;
}
.hover\:bg-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 69 0 / var(--tw-bg-opacity));
}
.hover\:font-bold:hover {
  font-weight: 700;
}
.hover\:text-\[\#FF4500\]:hover {
  --tw-text-opacity: 1;
  color: rgb(255 69 0 / var(--tw-text-opacity));
}
.hover\:text-red:hover {
  --tw-text-opacity: 1;
  color: rgb(255 69 0 / var(--tw-text-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.group:hover .group-hover\:visible {
  visibility: visible;
}
.group:hover .group-hover\:invisible {
  visibility: hidden;
}
.group:hover .group-hover\:translate-y-\[-100\%\] {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:bg-\[\#DD1B36\] {
  --tw-bg-opacity: 1;
  background-color: rgb(221 27 54 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-\[\#FF4500\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 69 0 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.group\/b:hover .group-hover\/b\:opacity-100 {
  opacity: 1;
}
.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
.aria-hidden\:invisible[aria-hidden=true] {
  visibility: hidden;
}
.data-\[quarterly\=true\]\:translate-x-\[29px\][data-quarterly=true] {
  --tw-translate-x: 29px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[current\=0\]\:bg-black[data-current="0"] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity));
}
.data-\[current\=0\]\:bg-white[data-current="0"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.data-\[current\=1\]\:bg-black[data-current="1"] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity));
}
.data-\[current\=1\]\:bg-white[data-current="1"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.data-\[current\=2\]\:bg-white[data-current="2"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.data-\[current\=3\]\:bg-black[data-current="3"] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity));
}
.data-\[current\=4\]\:bg-white[data-current="4"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.data-\[quarterly\=true\]\:bg-black[data-quarterly=true] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity));
}
.data-\[current\=0\]\:text-black[data-current="0"] {
  --tw-text-opacity: 1;
  color: rgb(21 21 21 / var(--tw-text-opacity));
}
.data-\[current\=1\]\:text-white[data-current="1"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.data-\[current\=2\]\:text-white[data-current="2"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.data-\[quarterly\=true\]\:text-white[data-quarterly=true] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.data-\[quarterly\=true\]\:accent-white[data-quarterly=true] {
  accent-color: #fff;
}
.data-\[active\=true\]\:opacity-100[data-active=true] {
  opacity: 1;
}
@media not all and (min-width: 1500px) {
  .max-2xl\:\!visible {
    visibility: visible !important;
  }
  .max-2xl\:\!invisible {
    visibility: hidden !important;
  }
  .max-2xl\:space-y-\[0\.6em\] > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.6em * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.6em * var(--tw-space-y-reverse));
  }
  .max-2xl\:\!opacity-100 {
    opacity: 1 !important;
  }
}
@media (min-width: 640px) {
  .sm\:text-\[60vw\] {
    font-size: 60vw;
  }
}
@media (min-width: 768px) {
  .md\:text-\[28vw\] {
    font-size: 28vw;
  }
}
@media (min-width: 1500px) {
  .\32xl\:w-\[40\%\] {
    width: 40%;
  }
  .\32xl\:py-\[0\.5em\] {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .\32xl\:text-\[1\.8vw\] {
    font-size: 1.8vw;
  }
  .\32xl\:text-\[7\.9vw\] {
    font-size: 7.9vw;
  }
  .\32xl\:opacity-0 {
    opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:opacity-100 {
    opacity: 1;
  }
}
/*# sourceMappingURL=/build/css-bundle-QTO723O5.css.map */
